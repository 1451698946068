import React, { useState } from 'react'
import { BsEye, BsEyeSlash } from "react-icons/bs"
import { GrLogout } from "react-icons/gr";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// ICONS
import logoImg from "../../assets/images/logo.png"
import instance from '../../Instance/instance';
import Spinner from '../../components/Spinner';

const Login = () => {
    const [eyeIcon, setEyeIcon] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [eyeView, setEyeView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailRegex, setEmailRegex] = useState(false);
    const navigate = useNavigate();

    var toLowerCaseEmail = email.toLocaleLowerCase()
    var trimEmail = toLowerCaseEmail.trim()

    // signIn Data to Send
    const signinCredential = {
        email: trimEmail,
        password: password,
        deviceId: "12345abc"
    }


    // handle sigin
    const handleSignIn = () => {
        // localStorage.setItem("isLogged", "true")
        // localStorage.setItem("token", true)
        // window.location.reload(true);
        // if (!email) {
        //     toast.warning("Email Field Required");
        // } else if (!password) {
        //     toast.warning("Password Field Required");
        // } else {
        //     let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        //     if (email.match(regexEmail)) {
        //         setLoading(true)
        //         return instance.post("signin", signinCredential).then((res) => {
        //             if (res?.data?.message === "Invalid User Credentials") {
        //                 setLoading(false)
        //                 toast.error("Invalid User Credential");
        //                 setLoading(false)
        //             } else if (res?.data?.token) {
        //                 setLoading(true)
        //                 localStorage.setItem("isLogged", "true")
        //                 localStorage.setItem("token", res?.data?.token)
        //                 window.location.reload(true);
        //             }
        //         }).catch((err) => {
        //             toast("Something Went Wrong");
        //             setLoading(false)
        //         });
        //     } else {
        //         setEmailRegex(true);
        //     }

        // }

        if (!email) {
            toast.warning("Email Field Required");
        } else if (!password) {
            toast.warning("Password Field Required");
        } else {
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!email.match(regexEmail)) {
                setEmailRegex(true);
            } else {
                setEmailRegex(false)
                setLoading(true);
                // return instance.post("api/User/Login", signinCredential)
                return instance.post(`api/User/SuperAdminLogin?email=${signinCredential?.email}&password=${signinCredential?.password}`,)
                    .then((res) => {
                        console.log(res.data?.message, "jkahsjkdhkjh")
                        if (res?.data?.message === "Invalid User Credentials") {
                            setLoading(false);
                            toast.error("Invalid User Credential");
                        } else if (res?.data?.message === "Invalid Password") {
                            setLoading(false);
                            toast.error("Invalid Password");
                        } else if (res?.data?.message === "Invalid Email") {
                            setLoading(false);
                            toast.error("Invalid Email");
                        } else if (res?.data?.message === "Login Successfully") {
                            const userType = res?.data?.data?.permission
                            console.log(res?.data?.token, "token")
                            setLoading(false);
                            localStorage.setItem("isLogged", true);
                            localStorage.setItem("token", res?.data?.token);
                            localStorage.setItem("name", res?.data?.data?.name);
                            localStorage.setItem("userType", userType == 2 ? "superadmin" : (userType == 1 ? "admin" : "user"));

                            window.location.reload(true);
                        }
                    }).catch((err) => {
                        console.log(err)
                        toast.error("Something Went Wrong");
                        setLoading(false);
                    });
            }
        }

    }

    const actionSign = (e) => {
        if (e.key === "Enter") {
            handleSignIn()
        }
    }

    return (
        <>
            <div style={{ height: "calc(100vh - 0px)" }} className='w-full loginGrid'>
                <div className='loginBg  hideME'>

                </div>
                <div className='p-12 bg-white'>
                    <img className='w-40 object-contain mt-1' src={logoImg} alt="logoImg" />
                    <div className='flex flex-col justify-center'>
                        <div className='mt-[178px]'>
                            <p className='text-[#6C757D] text-[16px] leading-8 font-semibold'>Sign In</p>
                            <p className='text-[12px] text-[#90979D] mt-1'>Enter your email address and password to access account.</p>

                            <div className='mt-7 flex flex-col'>
                                <label className='text-[#6C757D] font-medium text-sm'>Email address</label>
                                <input onChange={(e) => setEmail(e?.target?.value)} value={email} className='mt-3 py-2 outline-none px-4 border text-[13px] placeholder:text-[14px] border-[#3a3b3c55] bg-transparent rounded-sm' type="text" placeholder='Enter your Email' />
                                {emailRegex && <p className='text-sm text-red-600'>Invalid Email</p>}
                            </div>


                            <div className='mt-5 flex flex-col'>
                                <div className='flex justify-between items-center'>
                                    <label className='text-[#6C757D] font-medium text-sm'>Password</label>
                                </div>
                                <div className='relative w-full'>
                                    {eyeIcon ?
                                        <BsEye className='absolute cursor-pointer top-6 right-3' onClick={() => setEyeIcon(!eyeIcon)} /> :
                                        <BsEyeSlash className='absolute cursor-pointer top-6 right-3' onClick={() => setEyeIcon(!eyeIcon)} />
                                    }
                                    <input onKeyDown={(e) => actionSign(e)} onChange={(e) => setPassword(e?.target?.value)} value={password} className='mt-3 w-full py-2 outline-none px-4 pr-10 border text-[13px] placeholder:text-[14px] border-[#3a3b3c55] bg-transparent rounded-sm' type={eyeIcon ? "password" : 'text'} placeholder='Enter your Password' />
                                </div>
                            </div>

                            <button
                                onClick={loading ? () => { } : handleSignIn}
                                className='mt-5 w-full text-sm bg-red-500 flex justify-center items-center gap-1 px-4 py-2 text-white rounded-sm transition-all ease-in-out duration-100 hover:bg-red-600 '>
                                {!loading && <GrLogout size={14} />}
                                {loading ?
                                    <Spinner />
                                    : "Log In"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login