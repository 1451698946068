export const getRoles = (userType) => {
    const roles = {
        create: false,
        edit: false,
        redeem: false,
        hide: []
    };

    if (userType === 'superadmin') {
        roles.create = true;
        roles.redeem = false;
        roles.edit = true;
        roles.hide = ['']

    } else if (userType === 'admin') {
        roles.create = true;
        roles.redeem = false;
        roles.edit = false;
        roles.hide = ['admin']

    } else if (userType === 'user') {
        roles.create = false;
        roles.redeem = true;
        roles.edit = false;
        roles.hide = ['']

    } 
    return roles;
};