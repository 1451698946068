import React, { lazy } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense } from "react";
import Login from '../pages/Login';
import AdminPrivate from './adminPrivateRoute';
import Loader from '../components/Loader';

import useUserRole from '../hooks/useUserRole';
import { superAdminList } from './RouteList';

const MyRoutes = () => {
    const token = window?.localStorage?.getItem("token") ? true : false

    const Dashboard = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Dashboard")), 1300);
        });
    });

    const Attendance = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Attendance")), 1300);
        });
    });
    const Leaves = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Leaves")), 1300);
        });
    });
    const LeaveDetails = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/LeaveDetails")), 1300);
        });
    });
    const Overtime = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Overtime")), 1300);
        });
    });

    const AttendanceDetail = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/AttendanceDetail")), 1300);
        });
    });

    const DeletedEmployers = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/DeletedEmployers")), 1300);
        });
    });

    const ActiveEmployees = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/ActiveEmployees")), 1300);
        });
    });

    const RequestCheckout = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/RequestCheckout")), 1300);
        });
    });
    const PublicHolidays = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/PublicHolidays")), 1300);
        });
    });
    const SubAdmin = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/SubAdmin")), 1300);
        });
    });

    const roles = useUserRole()
    const filteredRoutes = superAdminList.filter(item => !roles?.hide?.includes(item?.type));

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {token ? <Route path="/dashboard" element={<Dashboard />}></Route> : <Route path="/login" element={<Login />}></Route>}
                    <Route element={<AdminPrivate token={token} />}>
                            {filteredRoutes?.map((item) => (
                                <Route key={item.path} path={`/${item.path}`} element={item.element} />
                            ))}

                        {/* <Route path="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/attendance" element={<Attendance />}></Route>
                        <Route path="/attendanceDetail" element={<AttendanceDetail />}></Route>
                        <Route path="/deletedEmployees" element={<DeletedEmployers />}></Route>
                        <Route path="/activeEmployess" element={<ActiveEmployees />}></Route>
                        <Route path="/requestCheckout" element={<RequestCheckout />}></Route>
                        <Route path="/over-time" element={<Overtime />}></Route>
                        <Route path="/leaves" element={<Leaves />}></Route>
                        <Route path="/leave-details" element={<LeaveDetails />}></Route>
                        <Route path="/public-holidays" element={<PublicHolidays />}></Route>
                        <Route path="/sub-admin" element={<SubAdmin />}></Route> */}
                    </Route>
                    <Route path="*" element={token ? (<Navigate to="/dashboard" replace />) : (<Navigate to="/login" replace />)}></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default MyRoutes