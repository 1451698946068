import React, { lazy } from 'react'
const Dashboard = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Dashboard")), 1300);
    });
});

const Attendance = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Attendance")), 1300);
    });
});
const Leaves = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Leaves")), 1300);
    });
});
const LeaveDetails = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/LeaveDetails")), 1300);
    });
});
const Overtime = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Overtime")), 1300);
    });
});

const AttendanceDetail = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/AttendanceDetail")), 1300);
    });
});

const DeletedEmployers = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/DeletedEmployers")), 1300);
    });
});

const ActiveEmployees = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/ActiveEmployees")), 1300);
    });
});

const RequestCheckout = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/RequestCheckout")), 1300);
    });
});
const PublicHolidays = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/PublicHolidays")), 1300);
    });
});
const SubAdmin = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/SubAdmin")), 1300);
    });
});



export const superAdminList = [
    {
        path: "/dashboard",
        type: "superadmin",
        element: <Dashboard />,
    },
    {
        path: "/attendance",
        type: "superadmin",
        element: <Attendance />,
    },
    {
        path: "/attendanceDetail",
        type: "superadmin",
        element: <AttendanceDetail />,
    },
    {
        path: "/deletedEmployees",
        type: "superadmin",
        element: <DeletedEmployers />,
    },
    {
        path: "/activeEmployess",
        type: "superadmin",
        element: <ActiveEmployees />,
    },
    {
        path: "/requestCheckout",
        type: "superadmin",
        element: <RequestCheckout />,
    },
    {
        path: "/over-time",
        type: "superadmin",
        element: <Overtime />,
    },
    {
        path: "/leaves",
        type: "superadmin",
        element: <Leaves />,
    },
    {
        path: "/leave-details",
        type: "superadmin",
        element: <LeaveDetails />,
    },
    {
        path: "/public-holidays",
        type: "superadmin",
        element: <PublicHolidays />,
    },
    {
        path: "/sub-admin",
        type: "admin",
        element: <SubAdmin />,
    },

]