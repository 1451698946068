import axios from "axios";
// import { jwtDecode } from "jwt-decode";

const token = localStorage?.getItem("token");
// if (token) {
//     const decoded = jwtDecode(token);
//     const currentTime = Date.now() / 1000;
//     if (decoded.exp < currentTime) {
//         window.localStorage.clear();
//         window.location.reload();
//     }
// }

const instance = axios.create({
    // baseURL: "http://192.168.100.17:8000/api/user",
    // baseURL: "https://test.linkitsoft.tech/api/user/",
    baseURL: "https://lisattendancesystem.azurewebsites.net/",
    headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        Authorization: `Bearer ${token}`,
    }
})



let isTokenExpiredModalVisible = false
console.log("isTokenExpiredModalVisible", isTokenExpiredModalVisible)
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        console.log("response", response)
        if (response?.data?.message === "Token is not valid") {
            localStorage.clear()
            window.location.reload()
            // console.log("isTokenExpiredModalVisible inside====")

            // isTokenExpiredModalVisible = true;
            // // Trigger an event to show the modal
            // const event = new Event("showTokenExpiredModal");
            // document.dispatchEvent(event);
            // if (isTokenExpiredModalVisible === false) {
                
            //     console.log("here")
            //     isTokenExpiredModalVisible = true;
            //     // Trigger an event to show the modal
            //     const event = new Event("showTokenExpiredModal");
            //     document.dispatchEvent(event);
            // }
        } 
        else {
            return response
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);



export default instance;