import { useUser } from '../context/userContext';
import { getRoles } from '../roleAndPermissions/getRoles';

const useUserRole = () => {
    const { userType } = useUser();
    // console.log(userType,"usertype-0-0-=0=-")
    if (userType === 'admin') return getRoles('admin');
    if (userType === 'superadmin') return getRoles('superadmin');
    if (userType === 'user') return getRoles('user');
};

export default useUserRole;